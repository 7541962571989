import React from 'react';

import withRoot from '../utils/withRoot';
import SEO from '../components/Seo';
import Page from '../components/Page';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';

const styles = theme => ({
  root: {
    height: '100%',
    padding: 32,
    margin: '80px auto',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: 800,
      width: '100%',
    },
  },
  title: {
    [theme.breakpoints.up('md')]: {
      fontSize: 36,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    display: 'block !important',
  },
});

function CookiePolicyPage(props) {
  const { classes } = props;

  return (
    <Page>
      <SEO title="MotiveBio - Cookie Policy" />

      <Grid container spacing={8} className={classes.root}>
        <Grid item xs={12}>
          <h1 className={classNames('typography-title mb-4', classes.title)}>
            Cookie Policy
          </h1>
          <p className="typography-p mb-2">
            This Cookie Policy describes how Motive Biosciences Inc. uses
            cookies and similar technologies to provide, customize, evaluate,
            improve, promote and protect our Services. Note that any capitalized
            terms not defined in this Cookie Policy have the meanings set forth
            in our Terms of Service. If you have any comments or questions about
            this Cookie Policy, feel free to contact us at
            privacy@motivebio.com.
          </p>
          <p className="typography-p mb-2">
            <b>Cookies. </b>Cookies are small pieces of text sent to your
            browser when you visit a site. They serve a variety of functions,
            like enabling us to remember certain information you provide to us
            as you navigate between pages on the Services. We use cookies on the
            website and associated domains of www.motivebio.com and on
            MotiveBio’s web and mobile applications for the following purposes:
          </p>

          <p className="typography-p mb-2">
            <b>
              Authentication, Customization, Security and Other Functional
              Cookies.{' '}
            </b>
            Cookies help us verify your Account and device and determine when
            you’re logged in, so we can make it easier for you to access the
            Services and provide the appropriate experiences and features. We
            also use cookies to help prevent fraudulent use of login credentials
            and to remember choices you’ve made on the Services, such as your
            language preference
          </p>

          <p className="typography-p mb-2">
            <b>Performance And Analytics. </b>Cookies help us analyze how the
            Services are being accessed and used, and enable us to track
            performance of the Services. For example, we use cookies to
            determine if you viewed a page or opened an email. This helps us
            provide you with information that you find interesting.
          </p>

          <p className="typography-p mb-2">
            <b>Third Parties. </b> Third Party Services may use cookies to help
            you sign into their services from our Services. Any such third party
            cookie usage is governed by the policy of the third party placing
            the cookie.
          </p>

          <p className="typography-p mb-2">
            <b>MotiveBio Ads. </b> We partner with third party publishers,
            advertising networks and service providers to manage our ads on
            other sites. Our third party partners may set cookies on your device
            or browser to gather information about your activities on the
            Services and other sites you visit, in order to provide you with
            MotiveBio ads. For example, if you visit MotiveBio and also use a
            social media platform, you may see a MotiveBio ad in your social
            media newsfeed or timeline.
          </p>

          <p className="typography-p mb-2">
            <b>Opting Out. </b> You can set your browser to not accept cookies,
            but this may limit your ability to use the Services. We currently
            don’t respond to DNT:1 signals from browsers visiting our Services.
            You can also opt out of receiving interest-based ads from certain ad
            networks here (or if located in the European Union, here).
          </p>

          <p className="typography-p mb-2">
            <b>Device Identifiers. </b> We use device identifiers on MotiveBio
            web and mobile applications to track, analyze and improve the
            performance of the Services and our ads.
          </p>

          <p className="typography-p mb-2">
            <b>Third Party Tags. </b>We use and manage third party tags on the
            website and associated domains of www.motivebio.com and on MotiveBio
            web and mobile applications. Third party tags may take the form of
            pixels or tracking snippets. We use pixels to learn how you interact
            with our site pages and emails, and this information helps us and
            our ad partners provide you with a more tailored experience. We use
            tracking snippets to capture data from your browser, make requests
            to a third party partner or set cookies on your device to store
            data. For example, if you see a MotiveBio ad on a social media
            platform and choose to use MotiveBio’s services, we may use a tag to
            reduce the number of MotiveBio ads you see on that platform. We use
            Google Tag Manager to manage our third party tag usage. This may
            cause other tags to be activated which may, for their part, collect
            data and set cookies under certain circumstances. Google Tag Manager
            does not store this data.
          </p>
          <p className="typography-p mb-4">
            We may update this Cookie Policy from time to time. When we make
            changes, we’ll update the “Effective Date” at the top of the Cookie
            Policy and post it on our sites. We encourage you to check back
            periodically to review this Cookie Policy for any changes since your
            last visit.
          </p>
        </Grid>

        <Grid item xs={12}>
          <h3 className={classNames('typography-title mb-2', classes.title)}>
            Associated Sites:
          </h3>
          <p className="typography-p mb-2">
            User can opt out of receiving interest-based ads from certain ad
            networks in the US or Europe.
          </p>
          <p className="typography-p mb-2">
            <a href="http://optout.networkadvertising.org/?c=1">
              http://optout.networkadvertising.org/?c=1
            </a>
          </p>

          <p className="typography-p mb-2">
            <a href="https://www.youronlinechoices.eu/">
              https://www.youronlinechoices.eu/
            </a>
          </p>

          <p className="typography-p mb-4">
            <a
              href="https://www.google.com/analytics/tag-manager/use-policy/
"
            >
              https://www.google.com/analytics/tag-manager/use-policy/
            </a>
          </p>
        </Grid>
      </Grid>
    </Page>
  );
}

export default withStyles(styles, { withTheme: true })(
  withRoot(CookiePolicyPage)
);
